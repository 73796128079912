.footer {
  background-color: $primary;
  padding-top: 20px;

  &__title {
    font-size: 16px;
    font-weight: 900;
    color: $white;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__map {
    height: 140px;
  }

  &__copyright {
    color: $white;
    text-align: center;
    padding: 14px 0;
    border-top: 1px solid $white;
  }
}

.f-contact {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  color: $white;

  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    a {
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}

.f-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__link {
    display: inline-block;
    color: $white;
    padding-left: 10px;
    position: relative;

    &:hover {
      color: $white;
    }

    &::before {
      content: '\f105';
      font-family: 'fontawesome';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.socials {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  margin-top: 16px;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__link {
    display: flex;
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    border: 1px solid $white;
    border-radius: 50%;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}
