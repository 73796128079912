.btn-default {
  border-color: $border-color;
  background-color: transparent;
  &:hover {
    border-color: $primary;
    color: $primary;
  }
}

.btn-icon {
  width: 41px;
  height: 41px;
  position: relative;
  .fa {
    width: 100%;
    height: 100%;
    color: $white;
    line-height: 41px;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    border: 8px solid transparent;
    position: absolute;
    left: 0;
    top: 0;
  }
}

$rounded-list: 8 10 18 20 22 24 25 26 28 30;
@each $item in $font-size-list {
  .rounded-#{$item} {
    border-radius: #{$item}px;
  }
}

@each $key, $val in $brand-colors {
  .btn-#{$key} {
    background-color: #{$val};
    box-shadow: 0 2px 2px rgba($val, 0.14), 0 3px 1px -2px rgba($val, 0.2),
      0 1px 5px 0 rgba($val, 0.12);
    transition: 0.3s;

    &:hover {
      color: nth($brand-colors, 1);
      box-shadow: 0 14px 26px -12px rgba($val, 0.42),
        0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($val, 0.2);
    }
  }
}

.social-sticky {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding-left: 0;
  padding-right: 10px;
  margin-bottom: 0;
  list-style: none;
  position: fixed;
  z-index: $zindex-sticky;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
  &__item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    transform: translateX(100%);
    &.is-show {
      transform: translateX(0);
    }
  }
}

.social-sticky-btn {
  position: fixed;
  z-index: $zindex-sticky;
  right: 0;
  bottom: 30px;
  transform: translateX(100%);
  transition: 0.3s;
  @media (max-width: 767px) {
    transform: translateX(-10px);
    &.is-hide {
      transform: translateX(100%);
      opacity: 0;
    }
  }
}

.btn-movetop {
  display: none;
  position: fixed;
  z-index: $zindex-sticky;
  bottom: 30px;
  right: 30px;
}
