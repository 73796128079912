.cart-product {
  position: relative;

  &__remove {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: 700;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.3s;

    &:hover {
      color: $primary;
      text-decoration: none;
      background-color: $gray-300;
    }
  }

  &__frame {
    @include frame('rect', 100%);
    @include frame('fade', rgba(#fff, 0.4));
    width: 60px;
    margin-right: 10px;
  }

  &__title {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 24px;
    margin-bottom: 2px;
  }

  &__info {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 2px;
  }

  &__color {
    display: inline-block;
    width: 40px;
    height: 12px;
    border: 1px solid #444;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__price {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: auto;
    padding-left: 4px;
    text-align: right;
  }
}

.cart {
  &__body {
    border: 1px solid $border-color;
    border-top: 0;
  }

  &__table {
    text-align: center;

    th {
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;
    }

    tbody {
      tr:hover {
        background-color: #e3f4ff;
      }
    }
  }

  &__frame {
    @include frame('rect', 70%);
    width: 88px;
    background-color: $white;
    margin: 0 auto;
    border: 1px solid $border-color;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
  }

  &__price {
    font-size: 18px;
    font-weight: 700;
    color: $primary;
    white-space: nowrap;
  }

  &__delete {
    color: $danger;

    &:hover {
      color: $danger;
      text-decoration: none;
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__btn {
    display: inline-block;
    margin-left: 28px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 2px 20px 0;
    line-height: 31px;
    border: 1px solid $border-color;
    border-radius: 18px;
    color: $gray-800;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    &--primary {
      color: $white;
      background-color: $primary;
      border-color: $primary;

      &:hover {
        color: $white;
      }
    }

    &--yellow {
      background-color: #f5e70c;
      border-color: #f5e70c;
    }
  }
}
