.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    margin: 4px 0 8px;
    position: relative;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    span {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      transition: 0.3s;

      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }

    &.active span {
      transform: rotate(90deg);
    }
  }

  &-sub {
    display: none;
  }

  &-sub &-link {
    padding-left: 40px;
  }

  &-sub-2 &-link {
    padding-left: 60px;
  }

  &-sub-3 &-link {
    padding-left: 80px;
  }

  &-item:hover > &-link {
    background-color: rgba($primary, 0.05);
  }

  &-root > &-item > &-link {
    font-weight: 700;
    text-transform: uppercase;
  }

  @media (min-width: #{$navbar-breakpoint}px) {
    &-root {
      display: flex;
      flex: 1;
      margin-right: 130px;
      justify-content: space-between;

      &-2 {
        margin-right: 0;
        margin-left: 130px;
      }
    }

    &-root > &-item {
      margin: 0;
      position: static;
    }

    &-root > &-item > &-link {
      padding: 0;
      font-size: 16px;
      line-height: 110px;
    }

    &-root > &-item:hover > &-link {
      color: $primary;
    }

    &-toggle {
      display: none;
    }

    &-item:hover > &-link {
      background: 0;
    }

    &-mega {
      display: none;
      width: 100%;
      z-index: 100;
      background-color: #e3f4ff;
      padding: 30px 0;
      position: absolute;
      top: 100%;
      left: 0;
      margin: 0 -15px;

      &::after {
        content: '';
        display: block;
        width: 110vw;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #e3f4ff;
      }
    }

    &-mega > &-item {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &-mega > &-item > &-link {
      font-size: 16px;
      font-weight: 700;
      padding: 0;
      text-transform: uppercase;
      line-height: 1.5;
      margin-bottom: 12px;

      &:hover {
        color: $primary;
      }
    }

    &-sub-2 {
      display: block;
    }

    &-sub-2 > &-item {
      margin: 0 0 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-sub-2 > &-item > &-link {
      padding: 0;
      line-height: 1.5;

      &:hover {
        color: $primary;
      }
    }

    &-root > &-item-group:hover > &-link {
      position: relative;

      &::after {
        content: '';
        border: 8px solid transparent;
        border-bottom-color: #e3f4ff;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-root > &-item-group:hover > &-mega {
      display: flex;
      animation: menu-mega-show 0.3s;
    }
  }
}

@keyframes menu-float-show {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes menu-mega-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
