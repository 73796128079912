.preview {
  &__thumbs {
    margin-top: 16px;
  }

  @media (max-width: 575px) {
    &__thumbs {
      margin-top: 8px;
    }
  }
}

.preview-slider {
  &__frame {
    @include frame('rect', 70%);
    border: 1px solid $border-color;
  }
}

.thumb-slider {
  position: relative;
  max-width: 400px;
  margin: 0 auto;

  &__frame {
    @include frame('rect', 70%);
    border: 1px solid $border-color;
  }

  &.has-nav {
    padding: 0 30px;
  }

  &.has-nav &__prev,
  &.has-nav &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 100%;
    font-size: 20px;
    color: $primary;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;

    &:focus {
      outline: 0;
    }
  }

  &.has-nav &__next {
    left: auto;
    right: 0;
  }

  &:not(.has-nav) &__prev,
  &:not(.has-nav) &__next {
    display: none;
  }
}
