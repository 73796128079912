.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    height: 110px;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__logo {
    display: inline-flex;

    img {
      height: 70px;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  @media (min-width: 1200px) {
    &__logo {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__btn {
      display: none;
    }
  }
}
