.md-video {
  &__body {
    padding: 0;
    position: relative;
  }

  &__close {
    display: flex;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    color: $white;
    background-color: $black;
    border: 2px solid $border-color;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(#000, 0.3);

    &::before,
    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 2px;
      background-color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotateZ(-45deg);
    }
  }

  @media (min-width: 1199px) {
    .modal-dialog {
      max-width: 1020px;
    }
  }
}

.md-search {
  background-color: rgba(#fff, 0.95);

  .modal-content {
    background: 0;
    border: 0;
  }

  .modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .input-group {
    border-bottom: 1px solid $primary;
  }

  .form-control {
    background: 0;
    border: 0;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .input-group-text {
    background: 0;
    border: 0;
  }

  @media (min-width: 1200px) {
    .form-control {
      font-size: 18px;
      letter-spacing: 3px;
    }

    .input-group-text {
      font-size: 18px;
    }
  }
}
