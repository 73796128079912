.video-slider,
.news-slider {
  position: relative;
  z-index: 1;
  margin: 0 -15px;

  .swiper-slide {
    padding: 15px;
  }

  &__prev,
  &__next {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    font-size: 30px;
    color: $primary;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -15px;
  }

  @media (max-width: 575px) {
    &__prev {
      left: 0;
    }

    &__next {
      right: 0;
    }
  }
}

.product-slider,
.partner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    font-size: 30px;
    color: $primary;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -30px;
  }

  @media (max-width: 575px) {
    &__prev {
      left: -15px;
    }

    &__next {
      right: -15px;
    }
  }
}

.product-slider-2 {
  position: relative;
  z-index: 1;
  margin: -8px;

  .swiper-slide {
    padding: 8px;
  }

  &__prev,
  &__next {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    font-size: 30px;
    color: $primary;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -20px;
  }

  @media (max-width: 575px) {
    &__prev {
      left: -6px;
    }

    &__next {
      right: -6px;
    }
  }
}

.partner-slider {
  &__item {
    display: block;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 50%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}

.banner-slider {
  position: relative;
  z-index: 1;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
  }

  &__box {
    border: 1px solid $white;
    width: 100%;
    max-width: 380px;
    padding: 20px;
    background-color: rgba(#000, 0.5);
    text-shadow: 0 1px rgba(#000, 0.6);
    box-shadow: 0 5px 15px rgba(#000, 0.15);
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 8px;
  }

  &__desc {
    color: $white;
    margin-bottom: 16px;
    font-size: 16px;
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    line-height: 40px;
    padding: 0 40px;
    border-radius: 20px;
    background-color: $white;
    color: #333;
    font-weight: 700;
    text-transform: uppercase;

    &:hover {
      color: #333;
      background-color: #f5f5f5;
      text-decoration: none;
    }
  }

  &__prev,
  &__next {
    display: flex;
    color: $white;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 15px;
  }

  &__frame {
    img {
      display: block;
      width: 100%;
      min-height: 470px;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  @media (max-width: 991px) {
    &__frame {
      height: auto;

      img {
        min-height: 150px;
      }
    }

    &__content {
      position: static;
      padding: 10px 0;
      text-align: center;
    }

    &__box {
      background: 0;
      text-shadow: unset;
      box-shadow: unset;
      border: 0;
    }

    &__title {
      color: #333;
    }

    &__desc {
      color: #333;
      font-size: 14px;
    }

    &__box {
      max-width: unset;
    }

    &__btn {
      background-color: #a9200f;
      color: $white;
    }

    &__prev,
    &__next {
      color: #333;
    }
  }
}
