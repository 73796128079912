.pagination {
  margin-bottom: 0;

  .page-item {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  .page-link {
    color: $gray-800;
    padding: 8px 16px;
    border-radius: 4px !important;
  }
}

.breadcrumb {
  &-item {
    &,
    & + &::before,
    &.active,
    &.active::before,
    a,
    a:hover {
      color: $gray-800;
    }
  }
}

.phone-ring {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: #e73a35;
  position: fixed;
  z-index: 100;
  bottom: 50px;
  left: 50px;
  box-shadow: 0 5px 20px rgba(#000, 0.3);

  &:hover {
    color: $white;
  }

  &::before {
    content: '';
    display: block;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 1px solid #e73a35;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: phone-circle-animate 1s ease-in-out infinite;
  }

  &::after {
    content: '';
    display: block;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: rgba(#e73a35, 0.5);
    animation: phone-circle-fill-animate 2s ease-in-out infinite;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba(#e73a35, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: phone-icon-animate 1s ease-in-out infinite;
  }

  &__text {
    display: inline-block;
    background-color: rgba(#e73a35, 0.7);
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    padding: 0 20px 0 60px;
    color: $white;
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    box-shadow: 0 5px 20px rgba(#000, 0.3);
  }

  &:hover &__text {
    color: #e73a35;
    background-color: rgba(#fff, 0.7);
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;

    &__text {
      display: none;
    }
  }
}

@keyframes phone-circle-animate {
  0% {
    transform: translate(-50%, -50%) rotate(0) scale(0.5);
    opacity: 0.1;
  }
  30% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.1;
  }
}

@keyframes phone-circle-fill-animate {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(0) scale(0.7);
    opacity: 0.2;
  }
  50% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
    opacity: 0.2;
  }
}

@keyframes phone-icon-animate {
  0%,
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20%,
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

.banner {
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
  }

  &__title {
    color: $white;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
  }

  &__breadcrumb {
    display: flex;
    justify-content: center;

    .breadcrumb-item {
      &,
      & + .breadcrumb-item::before,
      &.active,
      &.active::before,
      a,
      a:hover {
        color: $white;
      }
    }
  }
}

.contact {
  @include shadow('');
  padding: 30px;

  &__title {
    font-size: 25px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    margin-bottom: 16px;
  }

  &__desc {
    text-align: center;
    max-width: 450px;
    margin: 0 auto 24px;
  }

  &__btn {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 35px;
    padding: 0 16px;
    border-radius: 18px;
    background-color: $primary;
    color: $white;
    border: 0;
  }

  .form-control {
    border-radius: 0;
  }
}

.advice-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    border-bottom: 1px solid rgba($secondary, 0.3);
  }
}

.advice {
  padding: 15px 0;

  &__icon {
    margin-right: 14px;
    color: $secondary;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    padding: 6px 0;
    margin-right: auto;
  }

  &__time {
    padding: 6px 0;
    color: #999;
  }

  &__question {
    margin-bottom: 10px;
    text-align: justify;
  }

  &__toggle {
    color: $primary;
    text-decoration: underline;
  }

  &__answer {
    display: none;
    background-color: #fff8f8;
    padding: 16px;
  }

  &__answer-title {
    color: $primary;
    margin-bottom: 8px;
  }

  &__answer-text {
    margin-bottom: 20px;
    text-align: justify;
  }
}

.news {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include shadow('');

  &__frame {
    @include frame('rect', 75%);
    @include frame('zoom');
  }

  &__body {
    padding: 12px;
    flex-grow: 1;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__desc {
    @include max-line(2);
    text-align: justify;
  }
}

.video {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include shadow('');

  &__frame {
    @include frame('rect', 75%);

    &:hover {
      text-decoration: none;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.15;
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      transition: 0.3s;
    }

    &:hover::after {
      opacity: 0.5;
    }

    .fa {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $youtube;
      font-size: 60px;
    }
  }

  &__body {
    padding: 12px;
    flex-grow: 1;
  }

  &__title {
    @include max-line(2);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
  }
}

.post {
  &-title {
    font-size: 30px;
    font-weight: 700;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #999;
    margin-bottom: 1rem;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.as-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  border: 1px solid $border-color;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__link {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: $gray-800;
    padding: 12px 16px;
    position: relative;

    &:hover {
      color: $gray-800;
      text-decoration: none;
      background-color: $light;
    }

    &.active {
      color: $white;
      background-color: $primary;

      &:hover {
        color: $white;
        background-color: darken($primary, 5%);
      }
    }

    &::after {
      content: '\f105';
      font-family: 'fontawesome';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  &__sub {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  &__sub-item {
    border-top: 1px solid $border-color;
  }

  &__sub-link {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: $gray-800;
    padding: 14px 16px;
    position: relative;

    &:hover {
      color: $gray-800;
      text-decoration: none;
      background-color: $light;
    }

    &::after {
      content: '\f105';
      font-family: 'fontawesome';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }

    &.active {
      color: $white;
      background-color: $primary;

      &:hover {
        color: $white;
        background-color: darken($primary, 5%);
      }
    }
  }
}

.aside {
  border: 1px solid $border-color;

  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $primary;
    line-height: 30px;
    padding: 5px 15px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 0;
  }
}

.product {
  &__frame {
    @include frame('rect', 75%);
    @include frame('zoom');
    border: 1px solid $border-color;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 4px;
    margin-top: 12px;
  }

  &__price {
    text-align: center;
    margin-top: 4px;
  }

  &--shadow &__frame {
    @include shadow('');
  }
}

.product-2 {
  &__frame {
    @include frame('rect', 75%);
    @include frame('zoom');
    border: 1px solid $border-color;
    width: 100px;
    margin-right: 12px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  &__price {
    font-size: 16px;
    font-weight: 700;
    color: $primary;
  }
}

.pd-search {
  padding: 16px 12px;

  .form-control {
    height: 30px;
    border-radius: 15px 0 0 15px;
    border-right: 0;
  }

  .input-group-text {
    border: 0;
    border-radius: 0 15px 15px 0;
    background-color: $primary;
    color: $white;
  }
}

.pd-menu {
  list-style: none;
  margin-bottom: 0;
  padding: 12px;

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    color: $gray-800;
    padding-right: 21px;
  }

  &__toggle {
    display: flex;
    width: 21px;
    height: 21px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 12px;
      border-top: 1px solid $gray-900;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      transform: translate(-50%, -50%) rotateZ(90deg) rotateY(90deg);
      transition: 0.3s;
    }

    &.active::after {
      transform: translate(-50%, -50%) rotateZ(90deg) rotateY(0);
    }
  }

  &__sub {
    display: none;
    list-style: none;
    margin-bottom: 0;
    padding: 6px 0 6px 20px;
  }

  &__sub-link {
    display: inline-block;
    font-size: 14px;
    color: $gray-800;
  }
}

.payment {
  display: flex;

  &__item {
    display: inline-flex;
    height: 20px;
    align-items: center;

    img {
      max-height: 100%;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.product-info {
  &__title {
    font-size: 30px;
    font-weight: 900;
  }

  &__btn {
    font-size: 20px;
    font-weight: 700;
    padding: 8px 40px;
    border-radius: 23px;
    background-color: $primary;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($primary, 5%);
    }
  }

  @media (max-width: 575px) {
    &__btn {
      padding: 4px 20px;
      font-size: 16px;
    }
  }
}

.detail-tabs {
  border-bottom: 1px solid $border-color;
  margin-bottom: 20px;

  .nav-item {
    &:not(:last-child) {
      margin-right: 60px;
    }
  }

  .nav-link {
    font-size: 20px;
    font-weight: 700;
    color: $gray-800;
    padding: 10px 0;

    &:hover {
      color: $gray-800;
      text-decoration: none;
    }

    &.active {
      position: relative;
      color: $primary;

      &::after {
        content: '';
        display: block;
        width: 100%;
        border-bottom: 3px solid $primary;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
  }
}

.partners {
  padding: 36px 0;
  overflow: hidden;
}

.follow {
  padding: 12px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    border: 1px solid $white;
  }

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    z-index: -3;
    top: 0;
    left: 0;
  }

  &__inner {
    align-items: center;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    color: $white;
    text-shadow: 0 1px 1px $primary;
    margin-bottom: 8px;
  }

  &__desc {
    font-size: 16px;
    color: $white;
  }

  &__btn {
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    color: $primary;
    background-color: $white;
    border-radius: 25px;
    padding: 10px 40px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 991px) {
    &__inner {
      flex-direction: column;
    }

    &__title {
      text-align: center;
      font-size: 26px;
    }

    &__desc {
      text-align: center;
    }
  }
}

.intro {
  padding: 50px 0 30px;

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    img {
      height: 87px;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 20px;
  }

  &__item {
    overflow: hidden;
    position: relative;
    z-index: 1;
    @include shadow('xs');
  }

  &__frame {
    @include frame('rect', 100%);

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: rgba(#000, 0.15);
    }
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $white;
    padding: 16px;
    position: absolute;
    z-index: 12;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    margin-bottom: 8px;
  }

  &__desc {
    @include max-line(3);
    text-align: center;
    color: $white;
    margin-bottom: 20px;
  }

  &__btn {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: $primary;
    padding: 0 40px;
    line-height: 50px;
    border-radius: 25px;
    background-color: $white;
    box-shadow: 0 1px 3px rgba(#fff, 0.3);

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 991px) {
    &__logo {
      img {
        height: 60px;
      }
    }
  }
}

.home-tabs {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  margin-bottom: 30px;

  .nav-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 50px;
    }
  }

  .nav-link {
    font-size: 20px;
    font-weight: 700;
    color: $gray-800;
    padding: 0 40px;
    line-height: 45px;
    border: 1px solid $border-color;
    border-radius: 24px;

    &.active {
      color: $white;
      background-color: $primary;
    }
  }

  @media (max-width: 991px) {
    .nav-item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .nav-link {
      line-height: 33px;
      font-size: 16px;
      padding: 0 20px;
    }
  }
}

.welcome {
  padding: 20px 0;
  position: relative;
  z-index: 1;

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  &__title {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    color: $white;

    .text-orange {
      color: #f5e70c;
    }
  }

  &__subtitle {
    text-align: center;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__features {
    display: flex;
    flex-wrap: wrap;
  }

  &__feature {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    color: $white;
    font-weight: 700;
    font-size: 18px;
    margin-top: 16px;
    position: relative;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    img {
      height: 40px;
      margin-right: 16px;
    }

    &:not(:last-child)::after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) skewX(-15deg);
      border-right: 1px solid $white;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 24px;
    }

    &__subtitle {
      font-size: 14px;
    }

    &__feature {
      font-size: 16px;

      img {
        height: 30px;
      }
    }
  }

  @media (max-width: 575px) {
    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 13px;
    }

    &__features {
      flex-direction: column;
    }

    &__feature {
      max-width: 100%;
      margin-top: 12px;

      &:not(:last-child)::after {
        display: none;
      }
    }
  }
}

.sticky {
  position: fixed;
  z-index: 50;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: flex;
    width: 40px;
    height: 40px;
    border: 1px solid $white;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    padding: 2px;
    box-shadow: 0 1px 1px rgba(#000, 0.1);

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    background-color: $white;
    padding: 4px 8px;
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    transform: translateY(0);
    box-shadow: 0 -1px 3px rgba(#000, 0.1);

    &__item {
      &:not(:last-child) {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
  }
}

.sticky-cart {
  display: flex;
  width: 54px;
  height: 54px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: $primary;
  position: fixed;
  z-index: 100;
  top: 80px;
  right: 20px;
  box-shadow: 0 5px 20px rgba(#000, 0.3);

  &:hover {
    color: $white;
  }

  &::before {
    content: '';
    display: block;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 1px solid $primary;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: phone-circle-animate 1s ease-in-out infinite;
  }

  &::after {
    content: '';
    display: block;
    width: 160%;
    height: 160%;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background-color: rgba($primary, 0.5);
    animation: phone-circle-fill-animate 2s ease-in-out infinite;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba($primary, 0.7);
    border-radius: 50%;
    border: 5px solid rgba(#fff, 0.5);
    padding: 4px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    animation: phone-icon-animate 1s ease-in-out infinite;
    transition: 0.3s;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &:hover &__icon {
    background-color: $primary;
  }

  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
    top: auto;
    bottom: 20px;

    &__icon {
      border-width: 2px;
    }
  }
}
