.section {
  padding: 50px 0;
  overflow: hidden;

  &__title {
    font-size: 25px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    margin-bottom: 20px;
  }

  &__desc {
    text-align: center;
    max-width: 700px;
    margin: 0 auto 30px;
  }
}
